import { UserInfo } from 'src/app/models/login-model';
import { PriceListMultiplePayload, PriceListPayload, ResponsePriceList, ResponsePriceListMultiple } from './../../models/price-list-models';
import { ResponseShippingByTracking, ResponseShippingDetail, ResponseShippingDetailLight, ResponseShippingLight, ResponseShippingComplete, ShippingGrouped } from './../../models/response-models';
import {
  Country,
  ShippingDetail,
  ShippingDocument,
  ShippingDocumentPayload,
  ShippingDetailLight,
  ShippingPackage,
  ShippingStatus,
  ShippingLight,
  GetShippingDocumentPayload,
  ShippingLightPayload,
  ExternalTracking,
  ShippingDetailExternal,
  ShippingQuoteOdoo,
  ResponseOdooQuote,
  Condominium,
  CondominiumResident,
  ShippingMailRoom,
} from './../../models/shipping-models';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Observable, of, tap } from 'rxjs';
import { City } from 'src/app/models/shipping-models';
import { HttpHandlerErrorService } from './http-handler-error.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ShippingService {
  constructor(
    private http: HttpClient,
    private errorHandler: HttpHandlerErrorService,
    private spinner: NgxSpinnerService
  ) { }

  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  searchCities(query: string, country: string = 'IT') {
    return this.http
      .get<City[]>(
        `${environment.apiUrl}cities/search?search_query=${query}&country=${country}`,
        {
          headers: this.headers,
        }
      )
      .pipe(catchError((e: HttpErrorResponse) => of([])));
  }

  searchCountry(query: string) {
    return this.http
      .get<Country[]>(
        `${environment.apiUrl}countries/search?search_query=${query}`,
        {
          headers: this.headers,
        }
      )
      .pipe(catchError((e: HttpErrorResponse) => of([])));
  }
  searchCondominium(post_code: string, query: string, user_ref_id: number | null) {
    return this.http
      .get<Condominium[]>(
        `${environment.apiUrl}odoo/getCondominiums?post_code=${post_code}&search_query=${query}&user_ref_id=${user_ref_id}`,
        {
          headers: this.headers,
        }
      )
      .pipe(catchError((e: HttpErrorResponse) => of([])));
  }
  getCondominiumResidents(idCondominium: number, post_code: string) {
    return this.http
      .get<CondominiumResident[]>(
        `${environment.apiUrl}odoo/getCondominiumResidents/${idCondominium}?post_code=${post_code}`,
        {
          headers: this.headers,
        }
      )
      .pipe(catchError((e: HttpErrorResponse) => of([])));
  }
  storePackage(payload: ShippingPackage) {
    return this.http
      .post<ResponseShippingComplete>(
        `${environment.apiUrl}shippings/package`,
        payload,
        { headers: this.headers }
      )
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  storeDocument(payload: ShippingDocument) {
    return this.http
      .post<ResponseShippingComplete>(
        `${environment.apiUrl}shippings/document`,
        payload,
        { headers: this.headers }
      )
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  storeMailRoom(payload: ShippingMailRoom) {
    return this.http
      .post<ResponseShippingComplete>(
        `${environment.apiUrl}shippings/mailroom`,
        payload,
        { headers: this.headers }
      )
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getShippingsHistory(perPage: number = 15, page: number = 1, filters?: any) {

    const query = filters ? Object.keys(filters)
      .filter(key => !!filters[key])
      .map(key =>
      `${key}=${encodeURIComponent(filters[key].trim())}`
    ).join('&') : '';

    return this.http
      .get<ResponseShippingLight>(`${environment.apiUrl}shippings/history?per_page=${perPage}&page=${page}&${query}`, {
        headers: this.headers,
      })
      .pipe(catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e)));
  }

  getShippingsDetailHistory(perPage: number = 15, page: number = 1) {
    return this.http
      .get<ResponseShippingDetailLight>(`${environment.apiUrl}shippings/historyDetail?per_page=${perPage}&page=${page}`, {
        headers: this.headers,
      })
      .pipe(catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e)));
  }

  getShippingDetails(shippingId: number, perPage: number = 15, page: number = 1, last_page: boolean = false) {
    return this.http
      .get<ResponseShippingDetailLight>(`${environment.apiUrl}shippings/shippingDetails/${shippingId}?per_page=${perPage}&page=${page}&last_page=${last_page}`, {
        headers: this.headers,
      })
      .pipe(catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e)));
  }

  getDraftShipping(draft_shipping_id?: number | null) {
    return this.http
      .get<ResponseShippingComplete>(
        `${environment.apiUrl}shippings/draft/${draft_shipping_id ?? ''}`,
        {
          headers: this.headers,
        }
      );
  }

  getShippingById(id: number) {
    return this.http
      .get<ResponseShippingComplete>(
        `${environment.apiUrl}shippings/${id}`,
        {
          headers: this.headers,
        }
      );
  }

  getShippingLightById(id: number) {
    return this.http
      .get<ResponseShippingLight>(
        `${environment.apiUrl}shippings/light/${id}`,
        {
          headers: this.headers,
        }
      );
  }

  getShippingsToProcess(perPage: number = 15, page: number = 1) {
    return this.http
      .get<ResponseShippingDetailLight>(`${environment.apiUrl}shippings/shippingsToProcess?per_page=${perPage}&page=${page}`, {
        headers: this.headers,
      })
      .pipe(catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e)));
  }

  getShippingsToProcessGrouped(type: 'barcode' | 'filters', barcode: string, date: string, userId: number | null) {

    let query = '?grouped';

    if (type == 'barcode')
      query += `&barcode=${barcode}`
    else {
      if (date)
        query += `&date=${date}`;
      if (userId)
        query += `&userId=${userId}`
    }

    return this.http
    .get<ShippingGrouped[]>(`${environment.apiUrl}shippings/shippingsToProcessGrouped${query}`, {
      headers: this.headers,
    })
    .pipe(catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e)));
  }

  getPrintShippingsToProcess(barcode: string | null) {

    let query = '';
    if (barcode)
      query += `?barcode=${barcode}`

    const headers = new HttpHeaders({
      accept: 'application/pdf'
    });

    return this.http
      .get(`${environment.apiUrl}shippings/printShippingsToProcess${query}`,
        {
          headers: headers,
          responseType: 'blob',
          observe: 'response',
        })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  getPrintShippingsStock(from: string | null, to: string | null) {

    const headers = new HttpHeaders({
      accept: 'application/pdf'
    });

    return this.http
      .get(`${environment.apiUrl}shippings/printShippingsStock?date_from=${from}&date_to=${to}`,
        {
          headers: headers,
          responseType: 'blob',
          observe: 'response',
        })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getShippingDetail(idShipping: number) {
    return this.http
      .get<ResponseShippingDetail>(`${environment.apiUrl}shippings/detail/${idShipping}`, {
        headers: this.headers,
      })
      .pipe(catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e)));
  }

  getShippingDetailByTracking(tracking: string): Observable<ResponseShippingByTracking[] | null> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .get<ResponseShippingByTracking[]>(`${environment.apiUrl}shippings/tracking/${tracking}`, {
        headers: headers,
      })
      .pipe(
        catchError((e: HttpErrorResponse) => of([]))
      );
  }

  getPriceListByCities(payload: PriceListPayload): Observable<ResponsePriceList | null> {

    return this.http
      .post<ResponsePriceList>(`${environment.apiUrl}priceList/getByCities`, payload, {
        headers: this.headers,
      })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getPriceListByCitiesMultiple(payload: PriceListMultiplePayload): Observable<ResponsePriceListMultiple | null> {

    return this.http
      .post<ResponsePriceListMultiple>(`${environment.apiUrl}priceList/getByCitiesMultiple`, payload, {
        headers: this.headers,
      })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  updateShippingDetail(shippingDetail: ShippingDetail): Observable<ShippingDetailLight | null> {
    return this.http
      .put<ShippingDetailLight>(`${environment.apiUrl}shippings/updateDetail`, shippingDetail, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  updateShipping(shipping: ShippingLightPayload): Observable<ResponseShippingComplete | null> {
    return this.http
      .put<ResponseShippingComplete>(`${environment.apiUrl}shippings/update`, shipping, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getShippingDetailDocument(getShippingDocumentPayload: GetShippingDocumentPayload) {

    const headers = new HttpHeaders({
      accept: 'application/pdf'
    });

    return this.http
      .post(`${environment.apiUrl}shippings/shipping_document`,
        getShippingDocumentPayload,
        {
          headers: headers,
          responseType: 'blob',
          observe: 'response',
        })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getGenerateShippingDocument(shippingDocumentPayload: ShippingDocumentPayload) {

    const headers = new HttpHeaders({
      accept: 'application/pdf'
    });

    return this.http
      .post(`${environment.apiUrl}shippings/getGenerateShippingDocument`,
        shippingDocumentPayload,
        {
          headers: headers,
          responseType: 'blob',
          observe: 'response',
        })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getPreviewDocument(shippingDetailId: number) {
    const headers = new HttpHeaders({
      accept: 'application/pdf'
    });

    return this.http
      .get(`${environment.apiUrl}shippings/detail/${shippingDetailId}/preview_document`, {
        headers: headers,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  updateTrackingStatus(tracking: string, note: string, sign: string, trackingStatus: string, date?: string, userId?: number): Observable<string | null> {
    const payload = {
      "statusTrackingToUpdate": trackingStatus,
      "note": note,
      "sign": sign,
      "date": date,
      "userId": userId,
    };
    return this.http
      .post<any>(`${environment.apiUrl}shippings/updateTrackingStatus/${tracking}`,
        payload, {
        headers: this.headers
      })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  deleteTrackingStatus(idTrackingHistory: number): Observable<string | null> {
    const payload = {
      "idTrackingHistory": idTrackingHistory
    };
    return this.http
      .post<any>(`${environment.apiUrl}shippings/deleteTrackingStatus/`,
        payload, {
        headers: this.headers
      })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getShippingTrackingStatuses(): Observable<ShippingStatus[] | null> {
    return this.http
      .get<ShippingStatus[]>(`${environment.apiUrl}shippings/getShippingTrackingStatus`, {
        headers: this.headers,
      })
      .pipe(
        catchError((e: HttpErrorResponse) => of([]))
      );
  }
  
  getUsers(): Observable<UserInfo[] | null> {
    return this.http
      .get<UserInfo[]>(`${environment.apiUrl}shippings/users`, {
        headers: this.headers,
      })
      .pipe(
        catchError((e: HttpErrorResponse) => of([]))
      );
  }

  deleteShipping(idShipping: number): any {
    return this.http
      .delete(`${environment.apiUrl}shippings/${idShipping}`, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  deleteShippingDetail(idShippingDetail: number): any {
    return this.http
      .delete(`${environment.apiUrl}shippings/detail/${idShippingDetail}`, {
        headers: this.headers,
      })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  updateExternalTracking(shipping_detail_id: number, external_tracking: string, purchase_product: string | null): Observable<ExternalTracking[] | null> {
    return this.updateExternalTrackings([
      { shipping_detail_id, external_tracking, purchase_product },
    ]);
  }

  updateExternalTrackings(payload: ExternalTracking[]): Observable<ExternalTracking[] | null> {
    return this.http
      .post<ExternalTracking[]>(
        `${environment.apiUrl}shippings/updateExternalTrackings`,
        { details: payload },
        { headers: this.headers }
      )
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getExternalTrackings(shippingId: number): Observable<ShippingDetailExternal[] | null> {
    return this.http
      .get<ShippingDetailExternal[]>(`${environment.apiUrl}shippings/externalTrackings/${shippingId}`, {
        headers: this.headers,
      })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  createOdooQuote(shippingId: number, shippingQuoteOdoo: ShippingQuoteOdoo): Observable<ResponseOdooQuote | null> {
    return this.http
      .post<ResponseOdooQuote>(`${environment.apiUrl}odoo/createOdooQuote/${shippingId}`, shippingQuoteOdoo)
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  //   return this.http
  //     .post(`${environment.apiUrl}shippings/printEnvelopeSticker`,
  //       envelopeFormat ? { ...payload, envelopeStickerFormat: envelopeFormat } : payload,
  //       {
  //         headers: headers,
  //         responseType: 'blob',
  //         observe: 'response',
  //       })
  //     .pipe(
  //       catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
  //     );
  // }
  getPrintEnvelopeSticker(payload: any) {
    const headers = new HttpHeaders({
      accept: 'application/pdf',
      Authorization: `Bearer ${environment.authKey}`,
    });
    return this.http
      .post(`${environment.apiUrl}shippings/printEnvelopeSticker`,
        payload,
        {
          headers: headers,
          responseType: 'blob',
          observe: 'response',
        })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  printDistintaPoste(date_from: Date, date_to: Date, product_type?: string, sender_ids?: string[]) {
    const headers = new HttpHeaders({
      accept: 'application/zip',
      Authorization: `Bearer ${environment.authKey}`,
    });
    return this.http
      .post(`${environment.apiUrl}shippings/printDistintaPoste`,
        { date_from, date_to, product_type, sender_ids },
        {
          headers: headers,
          responseType: 'blob',
          observe: 'response',
        })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  checkIfMailRoomTokenExist(refCodeEmail: string) {
    return this.http.get(`${environment.apiUrl}shippings/checkIfMailRoomTokenExist/${refCodeEmail}`);
  }
  confirmReadMailroom(refCodeEmail: string) {
    return this.http.post(`${environment.apiUrl}shippings/confirmReadMailroom`, {
      'ref_code_email': refCodeEmail
    });
  }

  searchUserByMail(email: string) {
    return this.http
      .post<UserInfo[]>(
        `${environment.apiUrl}odoo/getUserByEmail`,
        {email},
        {
          headers: this.headers,
        }
      )
      .pipe(catchError((e: HttpErrorResponse) => of(null)));
  }

}
